.block.greys{
	position: relative;
	padding: 20px 20px;

	article{
		background: @gray3;
		color: #fff;
		padding: 20px;

		h1,h2,h3,h4,h5,h6{
			color: #fff;
			font-size: 4.5rem;
		}

		strong, b{
			font-weight: 700;
		}
	}

	li{
		list-style: none;
		padding: 0 0 30px;
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	article{
		box-sizing: border-box;
		width: 49%;
		padding: 45px;
	}
});


.desktop({

});
}
.block.square-blocks{
	position: relative;
	padding: 50px 20px;
	background: #F5F5F5;

	header{
		.bar-header;
		display: block;
		overflow: hidden;
	}

	.inner.alt{
		width: 100%;
		max-width: 100%;
	}

	ul{
		padding-top: 30px;
	}

	li{
		position: relative;
		width: 280px;
		margin: auto auto 10px;
		list-style: none;
	}

	li > a{
		position: relative;
		display: block;
		width: 280px;
		height: 280px;
		overflow: hidden;

		img{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: ease all .3s;
		}
		
		.content{
			position: absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			background: rgba(0,0,0,.5);
			align-items: center;
			text-align: center;
			color: #fff;
			width: 100%;
			padding-top: 40%;
			transition: ease all .3s;
			// opacity: 0;
			display: block;
	
			strong, b{
				display: block;
				text-align: center;
				width: 100%;
			}

			h3{
				width: 100%;
				font-size: 1.8rem;
				font-weight: bold;
				color: #fff;
				font-family: var(--main);
				text-align: center;
			}
		}
	
		&:hover{
			img{
				transform: scale(1.1);
			}
	
			.content{
				opacity: 1;
			}
		}
	}
		

	header h2{
		width: 100%;
	}

	h2 a{
		float: right;
		font-size: 20px;
		text-transform: uppercase;
		padding-top: 8px;
		font-family: @main;
		padding-right: 40px;
		background: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktYXJyb3ctcmlnaHQiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMSA4YS41LjUgMCAwIDEgLjUtLjVoMTEuNzkzbC0zLjE0Ny0zLjE0NmEuNS41IDAgMCAxIC43MDgtLjcwOGw0IDRhLjUuNSAwIDAgMSAwIC43MDhsLTQgNGEuNS41IDAgMCAxLS43MDgtLjcwOEwxMy4yOTMgOC41SDEuNUEuNS41IDAgMCAxIDEgOCIvPgo8L3N2Zz4=') center right no-repeat;
		background-size: 25px;
		background-position: right 7px;
	}
	

.tablet({
	// ul{
	// 	display: flex;
	// 	flex-wrap: wrap;
	// 	align-items: center;
	// 	justify-content: center;
	// }

	.inner.alt{
		margin-left: -20px;
		margin-right: -20px;
		width: 100vw;
		max-width: 100vw;
		overflow: hidden;
	}

	ul{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	li{
		position: relative;
		box-sizing: border-box;
		width: 25%;
		margin:0;
		border: 1px solid #fff;
	}

	li > a{
		width: 100%;
		.content{
			position: absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			background: rgba(0,0,0,.7);
			display: block;
			align-items: center;
			text-align: center;
			color: #fff;
			width: 100%;
			transition: ease all .3s;
			opacity: 0;
			padding-top: 48%;
	
			strong, b{
				display: block;
				text-align: center;
				width: 100%;
			}

			h3{
				width: 100%;
			}
		}
	
		&:hover{
			img{
				transform: scale(1.1);
			}
	
			.content{
				opacity: 1;
			}
		}
	}

});


.desktop({
	li {
		.content{
			background: none;
		}
	}
});
}

#rs-builder{
	.block.text-gallery {
		.iwrap:after{
			display: none;
		}

	}

	.block.square-blocks li{
		max-width: 25% !important;
	}

	.inner.alt{
		max-width: 100% !important;
	}
}
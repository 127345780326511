.block.hero{
	position: relative;
	padding: 25vh 0;
	text-align: center;
	color: #fff;
	font-size: 2.6rem;
	font-family: var(--heading);

	h1,h2,h3,h4,h5,h6{
		color: #fff;
		text-shadow: 0 0 10px rgba(0,0,0,0.8);
		font-size: 5rem;
		line-height: 1.2;
		position: relative;
		padding-bottom: 61px;

		&:after{
			position: absolute;
			bottom: 30px;
			height: 1px;
			left: 40%;
			right: 40%;
			content:"";
			background: #fff;
			opacity: .8;
		}
	}

	&.alt{
		padding: 0;
		height: 75vh;

		h1,h2,h3,h4,h5,h6{
			position: absolute;
			bottom: 5vh;
			left: 0;
			color: #fff;
			text-shadow: 0 0 10px rgba(0,0,0,0.8);
			font-size: 5rem;
			line-height: 1.2;
			font-family: 'Playfair Display', serif;

			&:before{
				position: absolute;
				left: 0;
				top: -15px;
				height: 3px;
				width: 120px;
				content: '';
				background: #fff;
			}
		}

		.inner{
			position: relative;
			height: 100%;
		}
	}

	&.video{
		overflow: hidden;
		padding: 0 !important;
		height: 100vh;

		video{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			min-height: 100%;
			object-fit: cover;
		}

		.inner{
			height:  100%;
			display: flex;
			align-items: center;
		}

		.content{
			width: 100%;
		}
	}

	&.video-mixer{
		overflow: hidden;
		padding: 0 !important;
		height: 100vh;

		video{
			position: absolute;
			display: none;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			min-height: 100%;
			object-fit: cover;
		}

		.inner{
			height:  100%;
			display: flex;
			align-items: center;
		}

		.content{
			width: 100%;
		}
	}

	.place-box{
		position: relative;
		background: rgba(255,255,255,.7);
		text-align: left;
		padding: 15px;
		color: #333;

		h1,h2,h3,h4,h5,h6{
			font-weight: bold;
			font-size: 2.5rem;
			text-shadow: none;
			color: #333;
			text-transform: none;
		}
	}

	.box-address{
		border-top: 2px solid #ddd;
	}

	.box-misc{
		a{
			text-decoration: underline;

			&:hover{
				text-decoration: none;
			}
		}
	}

	.box-actions{
		margin-top: 15px;
		display: flex;
		flex-direction: space-between;
		width: 100%;

		a{
			.button;
			display: block;
			background: #25282A;
			border: 0;
			float: left;
		}

		a + a{
			margin-left: 15px;
		}
	}

.tablet({
	padding: 28vh 0;

	&.video-mixer{
		video{
			display: block;
		}
	}

	h1,h2,h3,h4,h5,h6{
		font-size: 6.5rem;
	}

	.place-box{
		width: 600px;
		max-width: 100%;
		padding: 40px;


		h1,h2,h3,h4,h5,h6{
			font-size: 4.5rem;
		}
	}

	.box-misc{
		display: flex;
		justify-content: space-between;
	}

	.box-address{
		width: 55%;
		padding: 30px 0;
		font-size: 2rem;
		font-weight: bold;
	}

	.box-contact{
		width: 40%;
	}

	.box-actions{
		a{
			padding: 8px 20px;
			font-size: 2rem;
			font-weight: bold;
		}
	}
});


.desktop({

});
}
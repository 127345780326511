// standard colors
@text:			var(--text);
@gray-lt:		var(--gray-lt);
@gray-md:		var(--gray-md);
@gray-dk:		var(--gray-dk);
@black:			var(--black);
@white:			var(--white);
@orange:		var(--range);
@red:			var(--red);
@green: 		var(--green);
@blue: 			var(--blue);

// custom colors
@primary: 		var(--primary);
@secondary: 	var(--secondary);

@primaryHeading: var(--primaryHeading);
@secondaryHeading: var(--secondaryHeading);

@primaryText: 	var(--primaryText);
@secondaryText: var(--secondaryText);

// breakpoints
@phablet: 		480px;
@tablet: 		768px;
@desktop: 		980px;
@ultra: 		1300px;
@maxInner: 		1400px;

// font settings
@main: 			var(--main);
@heading: 		var(--heading);
@special: 		var(--special);

// misc
@maxwidth: 		var(--maxwidth);
@gray1:			var(--gray1);
@gray2:			var(--gray2);
@gray3:			var(--gray3);
@gray4:			var(--gray4);
@gray5:			var(--gray5);
@gray6:			var(--gray6);

.block.generic{
	position: relative;
	padding: 110px 20px;

	.inner{
		max-width: 840px;
	}

	h2,h3,h4,h5,h6{
		font-weight: bold;
	}

	p, ul, ol, dl{
		padding-bottom: 2rem;
	}

	li{
		list-style: inside;
	}

.tablet({
});


.desktop({

});
}
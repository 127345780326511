.block.asset-gallery{
	position: relative;
	
	.filter-bar{
		background: @gray3;
		padding: 20px;
		color: #fff;
		text-transform: uppercase;
		font-size: 1.5rem;
	}

	select{
		width: 150px;
		background: @gray3;
		border: 0;
		color: #fff;
		padding: 8px;
		margin-left: 15px;
	}

	.assets{
		position: relative;

		ul{
			padding-top: 30px;
		}

		li{
			position: relative;
			width: 280px;
			margin: auto auto 10px;
			list-style: none;
		}

		li > a{
			position: relative;
			display: block;
			width: 280px;
			height: 280px;
			overflow: hidden;

			img{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				transition: ease all .3s;
			}
			
			.preview{
				position: absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background: rgba(0,0,0,.5);
				display: flex;
				align-items: center;
				text-align: center;
				color: #fff;
				width: 100%;
				transition: ease all .3s;
				// opacity: 0;
		
				strong, b{
					display: block;
					text-align: center;
					width: 100%;
				}
			}
		
			&:hover{
				img{
					transform: scale(1.1);
				}
		
				.preview{
					opacity: 1;
				}
			}
			
		}
	}

	.asset-header,
	.asset-image{
		display: none;
	}

	.asset-details{
		padding: 20px;
		background: rgba(0,0,0,.7);

		*{
			color: #fff;
		}

		h1,h2,h3,h4,h5,h6{

		}

		a{
			.button;
		}
	}

	.asset-modal{
		position: fixed;
		top:0;
		left:0;
		right:0;
		bottom:0;
		background: rgba(0,0,0,.3);
		z-index: 1000;
		padding-top: 15vh;
		// opacity: 0;
		display: none;
		overflow-y: scroll;
	}

	.asset-box{
		position: relative;
		max-width: 800px;
		margin: auto;
	}

	.asset-header{
		position: relative;
		display: block;
		height: 72px;
		background: @gray3;
		color: #fff;

		h1,h2,h3,h4,h5,h6{
			padding: 0 0 0 25px;;
			margin:0;
			font-size: 2.2rem;
			color: #fff;
			line-height: 72px;
		}

		span{
			position: absolute;
			top:0;
			right: 0;
			bottom:0;
			width: 72px;
			text-align: center;
			line-height: 72px;
			font-size: 30px;
			color: #fff;
			padding-top: 4px;

			svg{
				height: 26px;
				width: 26px;
			}
		}
	}

	.asset-image{
		width: 100%;
		display: block;
	}

	.asset-details{
		background: @gray3;
		display: flex;
		justify-content: space-between;
		font-size: 1.6rem;

		h1,h2,h3,h4,h5,h6{
			font-size: 2.2rem;
		}
	}

	.m{
		width: 60%;
	}

	.a{
		width: 40%;
		align-items: center;
		display: flex;
		text-align: right;

		svg{
			margin-left: 5px;
		}
	}

	nav{
		position: relative;
		border: 1px solid #111;
		margin-bottom: 50px;
		margin: auto;
		width: auto;

		a{
			position: relative;
			line-height: 25px;
			padding: 10px 20px;
			color: #000;
			font-weight: bold;
			text-align: center;
			align-items: center;
			display: block;
			// width: 12.5%;
			height: 50px;

			&:hover, &.active{
				background: #000;
				color: #fff;
			}
		}
	}

.tablet({
	.assets{
		.inner;

		ul{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}

		li{
			position: relative;
			box-sizing: border-box;
			width: 25%;
			margin:0;
			border: 1px solid #fff;
		}

		li > a{
			width: 100%;
			.preview{
				position: absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background: rgba(0,0,0,.7);
				display: flex;
				align-items: center;
				text-align: center;
				color: #fff;
				width: 100%;
				transition: ease all .3s;
				opacity: 0;
		
				strong, b{
					display: block;
					text-align: center;
					width: 100%;
				}
			}
		
			&:hover{
				img{
					transform: scale(1.1);
				}
		
				.preview{
					opacity: 1;
				}
			}
		}
	}
	
});


.desktop({
	text-align: center;
	.block-nav{
		display: inline-block;
		margin: auto;
	}
	nav{
		position: relative;
		border: 1px solid #111;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto auto 50px;
		
		a{
			position: relative;
			line-height: 25px;
			min-height: 50px;
			padding: 10px 20px;
			color: #000;
			text-align: center;
			align-items: center;
			justify-content: center;
			display: flex;
			font-weight: normal;

			&:hover{
				background: #000;
				color: #fff;
			}
		}
	}
	
	.assets{
		width: 100%;
		max-width: 100%;
	}
});
}

@media screen and (max-width: 768px){
	.block.asset-gallery .block-nav{
		nav{
			border:0;
			width: 300px;
			margin-bottom: 30px;
			a{
				display: none;
				background: none; 
				color: #000;
				text-align: left;
				font-weight: normal;
				height: auto;
				padding-left: 0;

				&.active{
					border-bottom: 1px solid #000;
					display: block;
					background: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4Ii8+Cjwvc3ZnPg==') no-repeat;
					background-size: 16px;
					background-position: right center;
				}

				br{
					display: none;
				}
			}

			&.show{
				a{
					display: block;
				}
			}
		}
	}
}
.block.faq{
	position: relative;
	padding: 20px;

	.faq-item{
		padding-bottom: 40px;
	}

	.faq-header{
		position: relative;
		padding: 20px;
		// height: 50px;
		background: #25282A;
		overflow: hidden;

		img{
			float: left;
			height: 50px;
		}

		div{
			float: left;
		}

		h2,h3,h4,h5,h6{
			// height: 50px;
			line-height: 52px;
			font-size: 2.8rem;
			font-weight: bold;
			color: #fff;
			padding-left: 10px;
		}

		.icon{
			position: absolute;
			top:9px;
			right:5px;
			bottom: 5px;

			svg{
				height: 74px;
				width: 85px
			}
		}
	}

	.faq-content{
		padding: 20px;
		// display: none;
	}

.tablet({

	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.col{
		width: 49%;
	}
	
});


.desktop({

});
}
.block.partners{
	position: relative;
	padding: 80px 20px;
	background: #25282A;

	header{
		.bar-header;
		&:before{
			background: @gray3;
		}
		h1,h2,h3,h4,h5,h6{
			color: #fff !important;
		}
	}

	li{
		list-style: none;
	}

	img{
		height: 230px;
		width: 230px;
		border-radius: 115px;
	}

	figure{
		padding:0;
		margin: 0;
	}


.tablet({

	ul{
		display: flex;
		flex-wrap: wrap;

		li{
			width: 25%;
		}

		img{
		}
	}
});


.desktop({

});
}
#sketchbar{
	position: fixed;
	top:0;
	left:0;
	bottom:0;
	right: 0;
	background: url(https://media.thinkresite.cloud/hmbgo.jpg) center no-repeat;
	background-size: cover;
	z-index: 20000;
	display: none;

	&:before{
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		right: 0;
		content:"";
		background: rgba(255,255,255,.5);
	}

	.inner{
		height: 100vh;
		display: flex;
		align-items: center;
		text-align: center;
	}

	#close-bar{
		position: absolute;
		top: 35px;
		left:0;
		height: 60px;
		width: 60px;

		svg{
			height: 35px;
			width: 35px;
		}
	}

	ul{
		list-style: none;
		text-align: center;
		width: 100%;
		font-size: 3.2rem;
		text-transform: uppercase;
	}

	a.small, li.small{
		font-size: 2.5rem;
	}

	a{
		color: #333;
		font-family: @heading;

		&:hover{
			color: @gray3;
		}
	}


.tablet({
	ul{
		font-size: 4.6rem;
	}

	a.small, li.small{
		font-size: 2.9rem;
	}

});


.desktop({

});
} 
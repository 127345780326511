html { 
	font-size: 62.5%; 
} 

body{
	font-family: @main;
	font-size: 1.7rem;
	line-height: 1.6;
	color: @text;
	font-family: @main;
	font-weight: 400;
}

img{
	display: block;
}

#mainwrap{
	overflow: hidden;
}

.inner{
	position: relative;
	margin: auto;
	max-width: @maxwidth;
	width: 100%;

	> header{
		padding-bottom: 35px;
	}
}



.tablet({
	
});
.block.report{
	position: relative;
	padding: 20px;
	background: #838383;

	header{
		.bar-header;
		padding-bottom: 0!important;
		text-align: center;

		h1,h2,h3,h4,h5,h6{
			float: none;
			color: #fff;
		}

		&:before{
			display: none;
		}

		div{
			width: 100%;
		}
	}

	form{
		padding-top: 40px;
	}
	input[type="submit"]{
		background: none;
		border: 1px solid #eee;
		color: #fff !important;
		width: 220px;
		margin: auto;
		display: block;
	}

	label{
		display: none;
	}
	
.tablet({
	form{
		margin: auto;
		max-width: 768px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.form-group{
		width: 49%;

		&.full{
			width: 100%;
		}
	}

	textarea{
		height: 200px;
	}
	
});


.desktop({

});
}
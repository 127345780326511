#topbar{
	position: relative;
	background: #25282A;
	color: #fff;
	text-align: center;
	height: 60px;
	padding: 20px;
	transition: ease all .3s;

	.logo{
		height: 40px;
		width: 120px;
		margin: auto;

		a{
			height: 60px;
			align-items: center;
			display: flex;
		}
	}

	#grip{
		position: absolute;
		top: 0;
		left:0;
		height: 60px;
		width: 60px;

		svg{
			margin-top: 10px;
			width: 40px;
			height: 40px;
		}
	}

.tablet({

	.logo{
		height: 60px;
		width: 230px;
		margin: auto;

		a{
			height: 60px;
			align-items: center;
			display: flex;
		}
	}
});


.desktop({
	position: fixed;
	top:0;
	left:0;
	right:0;
	background: none; 
	background: rgba(37, 40, 42, .75);
	z-index: 100000;
});
}

.scrolled #topbar{
	background: #25282A;
}
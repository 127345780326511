.block.counts{
	position: relative;
	padding: 50px 20px;

	.inner{
		display: flex;
		flex-wrap: wrap;
	}

	ul{
		position: relative;
		text-transform: uppercase;
		color: #111;
		width: 100%;
	}
		
	li{
		list-style: none;
		font-size:  5rem;
		line-height: .8em;
		text-align: center;
		padding-bottom: 15px;
	}

	strong, b{
		display: block;
		font-weight: normal;
		font-size: 1.3rem;
		color: @gray3;
	}

	article{
		text-align: center;
		color: #111;
		font-size: 1.8rem;
		width: 100%;
	}

	header{
		.bar-header;
		width: 30%;
	}

.tablet({
	padding: 100px 0 150px;

	ul{
		display: flex;
		justify-content: space-between;
		align-items: center;

		strong, b{
			padding-top: 15px;
			line-height: 1em;
		}

		li{
			width: 33%;
			padding-bottom: 0;
		}

		li:nth-of-type(2){
			border-right: 1px solid #ddd;
			border-left: 1px solid #ddd;
		}

		li:nth-of-type(4){
			border-left: 1px solid #ddd;
		}
	}

	article{
		font-size: 3.75rem;
		margin: auto;
		max-width: 900px;
		line-height: 1.2;
		padding-top: 110px;
	}

	header{
		width: 30%;
	}

	header + ul{
		width: 65%;

		li{
			font-size: 4rem;
		}

		margin-top: -12px;
	}
});


.desktop({

});
}

#mainwrap > .block.counts:first-child{
	padding-top: 200px;
}
.block.modblocks{
	position: relative;
	padding: 20px;

	li{
		list-style: none;
	}

.tablet({
	li{
		position: relative;
		list-style: none;
		height: 370px;
		margin-bottom: 30px;
	}

	.mod-image{
		height: 100%;

		img{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: ease all .3s;
		}
	}

	.mod-content{
		position: absolute;
		bottom:0;
		left:0;
		right:0;
		padding: 20px;
		background: #25282A;
		color: #fff;
		font-size: 1.6rem;

		h1,h2,h3,h4,h5,h6{
			margin:0;
			padding:0;
			color: #fff;
			font-size: 2.6rem;
			font-weight: bold;
			padding-bottom: 5px;
			display: block;
		}
	}

	ul{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	img{
		filter: grayscale(100%);
	}

	li:nth-of-type(1){
		width: 55%;
	}
	
	li:nth-of-type(2){
		width: 42.5%;
	}
	
	li:nth-of-type(3){
		width: 42.5%;
	}
	
	li:nth-of-type(4){
		width: 55%;
	}

	.mod-content{
		height: 34px;
		overflow: hidden;
		box-sizing: content-box;
		transition: ease all .3s;
		p{
			display: none;
		}
	}

	li:hover {
	
		img{
			filter: grayscale(0%);
		}

		.mod-content{
			height: auto;
	
			p{
				display: block;
			}
		}
	}
	
});


.desktop({

});
}
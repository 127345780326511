.block.location{
	position: relative;
	padding: 80px 20px;

	article{
		padding:20px;
		background: #25282A;
		color: #fff;

		h1,h2,h3,h4,h5,h6{
			color: #fff;
		}
	}
	aside{
		img{
			display: block;
		}

		iframe{
			height: 100% !important;
			min-height: 450px !important;
			border: 0 !important;
			width: 100% !important;
		}
	}
	
.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	article{
		position: relative;
		width: 50%;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		font-size: 2rem;

		&:before{
			position: absolute;
			top:0;
			left: -9999px;
			width: 9999px;
			bottom:0;
			content:"";
			background: @gray3;
		}

		> *{
			width: 100%;
			text-align: center;
		}

		h1,h2,h3,h4,h5,h6{
			font-size: 4.8rem;
			font-weight: bold;
			padding-bottom: 40px;
		}
	}

	aside{
		width: 50%;
	}
});


.desktop({

});
}
.block.text-gallery{
	position: relative;
	padding: 50px 20px;

	header{
		.bar-header;
		display: block;
		overflow: hidden;
	}

	li{
		position: relative;
		list-style: none;
	}

	.iwrap{
		position: relative;
		margin-bottom: 20px;
		overflow: hidden;

		&:after{
			position: absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			content:"";
			background: #19263C;
			opacity: .6;
		}
	}

	img{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.content{
		position: absolute;
		left:0;
		right:0;
		bottom:0;
		padding: 20px;
		z-index: 100;
		color: #fff;
		font-weight: bold;

		h1,h2,h3,h4,h5,h6{
			color: #fff;
			font-weight: bold;
			font-size: 2.4rem;
		}

		a{
			color: #EFDBB2;
		}
	}

	li{
		width: 100%;
		height: 400px;
	}

	.iwrap{
		height: 100%;
		
	}



.tablet({
	background: #F5F5F5;
	padding: 100px 0;
	ul{
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	
	li{
		position: relative;
		width: 24%;
		background: rgba(25, 38, 60, .7);
	}

	header h2{
		width: 100%;
	}

	h2 a{
		float: right;
		font-size: 20px;
		text-transform: uppercase;
		padding-top: 8px;
		font-family: @main;
	}

});


.desktop({

});
}

#rs-builder{
	.block.text-gallery {
		.iwrap:after{
			display: none;
		}

		.content{
			background: #19263C;
		}
	}
}
.block.history{
	position: relative;
	padding: 80px 20px;

	nav{
		display: none;
	}

	header{
		.bar-header;
	}


	ul{
		position: relative;
	}

	li{
		position: relative;
		height: 593px;
		width: 286px;
		overflow: hidden;
		margin: auto auto 20px;
		opacity: 1;
		transition: opacity 0.5s;
		transition-delay: 0s;

		&.hide{
			opacity: 0;
		}
	}

	figure{
		position: absolute;
		padding:0;
		margin:0;
		top:0;
		left:0;
		right:0;
		bottom:0;

		img{
			position: absolute;
			object-fit: cover;
			height: 100%;
		}
	}

	.info{
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		background: rgba(0,0,0,.5);
		padding: 40px 15px 0 15px;
		color: #fff;
	}

	.year{
		position: absolute;
		bottom: 20px;
		left: 15px;
		font-size: 42px;
		color: #fff;
		font-style: italic;
		font-family: @special;
	}


.tablet({
	ul{
		display: flex;
		justify-content: start;
	}
	li{
		width: 25%;
		box-sizing: border-box;
		border: 1px solid #fff;
	}

	.info{
		opacity: 0;

		&:hover{
			opacity: 1;
			transition: ease all .3s;
		}
	}

	nav{
		display: block;
		position: absolute;
		bottom: 350px;
		left:0;
		right:0;
		height: 0;

		button{
			float: left;
			width: 80px;
			height: 132px;
			border:0;
			background: url(../img/ugh-left.png) center no-repeat;

			svg{
				display: none;
			}

			&.disabled{
				opacity: .4;
			}
		}

		button + button{
			float: right;
			background: url(../img/ugh-right.png) center no-repeat;
		}
	}
});


.desktop({

});
}


#rs-builder .block.history{
	.info{
		top: 40px;
	}

	nav{
		display: none;
	}

	ul{
		flex-wrap: wrap;
	}
}
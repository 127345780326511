.block.silly-slider{
	position: relative;
	padding: 80px 20px;
	
	li{
		list-style: none;
		padding-bottom: 15px;
	}

.tablet({
	padding: 80px 120px;
	ul{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	li{
		width: 32%;
	}

	#ss-left{
		position: absolute;
		top:45px;
		left:0;
		margin-left: -120px;
		width: 80px;
		height: 132px;
		border:0;
		background: url(../img/ugh-left.png) center no-repeat;
	}

	#ss-right{
		position: absolute;
		top:45px;
		right:0;
		float: right;
		margin-right: -120px;
		width: 80px;
		height: 132px;
		border:0;
		background: url(../img/ugh-right.png) center no-repeat;
	}
});


.desktop({

});
}
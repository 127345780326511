.block.tour{
	position: relative;
	padding: 80px 20px;

	header{
		.bar-header;
	}

	form{
		padding-top: 40px;
	}
	input[type="submit"]{
		background: @gray3;
		color: #fff !important;
		width: 220px;
		margin: auto;
		display: block;
	}
	
.tablet({
	form{
		margin: auto;
		max-width: 768px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.form-group{
		width: 49%;

		&.full{
			width: 100%;
		}
	}

	textarea{
		height: 200px;
	}
	
});


.desktop({

});
}
.block.jobs{
	position: relative;
	padding:20px;

	.job{
		padding: 20px;
		background: #f5f5f5;
		margin-bottom: 15px;
	}

	.job-content {
		ul, ol {
			margin-left: 20px;
		}
	}

	.job-header{
		position: relative;
		overflow: hidden;
		padding-bottom: 30px;

		div{
			float: left;
		}

		div + div{
			float: right;
			text-align: right;
			line-height: 1.2;
			color: #222;
		}

		h1,h2,h3,h4,h5,h6{
			font-size: 2.3rem;
			font-weight: bold;
		}
	}

	.job-footer{
		text-align: right;

		a{
			.button;
			background: @gray2;
			font-size: 1.4rem;
			padding: 0px 25px;
		}
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.job{
		width: 49%;
		box-sizing: border-box;
	}
});


.desktop({

});
}
.block.texter{
	position: relative;
	padding: 80px 20px;

	header{
		.bar-header;
	}

	aside{
		padding: 50px 0 0;
		ul{
			position: relative;
			list-style: none;
			color: @gray3;
			font-weight: bold;
		}
	}

	article, aside{
		p, ul, ol, dl{
			padding-bottom: 1.5em;
		}
		
		line-height: 2;
	}
	
	.video{
		width: 100%;
	}


.tablet({
	.inner{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	header{
		width: 30%;
	}

	article{
		width: 65%;
	}

	&.left-title-text.image{
		header{
			width: 100%;
		}

		article{
			width: 40%;
			align-items: center;
			display: flex;
		}

		figure{
			width: 55%;
			padding:0;
			margin:0;
		}
	}

	&.left-title-text.list{
		header{
			width: 100%;
		}

		article, aside{
			width: 100%;
		}

		ul{
			max-width: 80%;
			margin: auto;
			display: flex;
			flex-wrap: wrap;
		}

		li{
			width: 33.3%;
			padding: 0 0 50px;
		}
	}

	&.left-text-video{
		.main{
			width: 49%;

			> * {
				width: 100%;
			}
			article{
				font-size: 1.6rem;
			}
		}

		.video{
			width: 49%;
		}
	}
});


.desktop({

});
}
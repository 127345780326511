.block.video{
	position: relative;
	background: #ddd;
	margin-bottom: 40px;

	.inner{
		max-width: 100%;
	}

	&.small{
		background: none;
		.inner{
			max-width: 760px;
		}
	}

	&.simple{
		position: relative;
		width: 100%;
		padding:0 !important;
		margin:0 0 40px 0 !important;
		background: none !important;

		video{
			position: relative;
			width: 100% !important;
			max-width: 100% !important;
			height: auto !important;
			max-height: none;
			padding:0 !important;
			margin: 0 !important;
		}
	}
.tablet({
	
});


.desktop({

});
}
.block.hero-image{
	position: relative;
	padding: 25vh 0;
	text-align: center;

	h1,h2,h3,h4,h5,h6{
		color: #fff;
		text-transform: uppercase;
		text-shadow: 0 0 10px rgba(0,0,0,0.8);
		font-size: 5rem;
		line-height: 1.2;
	}

.tablet({
	padding: 29vh 0;
});


.desktop({

});
}
.block.center-feature{
	position: relative;
	padding: 30px;
	color: #fff;
	overflow: hidden;


	h1,h2,h3,h4,h5,h6{
		color: #fff;
		text-align: center;
	}

	article{
		position: relative;
		padding: 20px;
		color: #fff;
		text-align: center;
		box-sizing: border-box;
		max-width: 800px;
		margin: auto;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #25282A;
			opacity:.95;
			z-index: 1;
			// opacity: .9;
		}

		*{
			position: relative;
			z-index: 2;
		}

		strong, b{
			text-transform: none;
			display: inline;
		}

		a{
			.button;
		}

		p{
			padding-bottom: 15px;
		}
	}


	&.light{
		article{
			&:before{
				background: #fff;
				opacity: .7;
			}

			h1,h2,h3,h4,h5,h6{
				color: #111;
			}
			color: #111;

			a{
				border: 1px solid #111;
				color: #111;
			}
		}
	}


.tablet({
	padding: 140px 0;
	article{
		padding: 40px 20px;
		h1,h2,h3,h4,h5,h6{
			font-size: 4.5rem;
			max-width: 80%;
			margin: auto;
		}

		strong, b{
			font-weight: 900;
		}

		a{
			padding: 15px 90px;
		}
	}

	.plax{
		position: absolute;
		top: 0;
		left: -200px;
		right: -200px;
		bottom: -350px;
		background-attachment: scroll;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
});


.desktop({

});
}
.block.counts + markup .block.team{
	margin-top: -150px;
}

.block.team{
	position: relative;
	padding: 80px 20px;
	overflow: hidden;
	text-align: center;

	.inner{
		max-width: 100%; // this is stupid as fuck
	}

	ul{
		position: relative;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	li{
		position: relative;
		width: 100%;
		list-style: none;
		overflow: hidden;
		text-align: left;
		user-select: none;
    cursor: pointer;

		&:hover img{
			filter: grayscale(0);
		}

		&.active, &:hover{
			&:after{
				position: absolute;
				bottom: -30px;
				left: 42%;
				height: 40px;
				width: 40px;
				content: " ";
				background: #25282A;
				transform: rotate(45deg);
			}

			img{
				filter: grayscale(0);
			}
		}
	}

	img{
		display: block;
		width: 100%;
		filter: grayscale(1);
		transition: ease all .4s;
	}

	.bio{
		position: absolute;
		bottom: 0;
		left:0;
		right:0;
		padding: 12px;
		background: rgba(37, 40, 42, 0.70);
		text-align: left;

		*{
			font-size: 1.45rem;
			line-height: 1.7;
			padding:0;
			margin:0;
			color: #fff;
		}

		svg{
			position: absolute;
			bottom: 10px;
			right: 10px;
			height: 20px;
			width: 20px;
		}

		svg + svg{
			display: none;
		}
	}

	li.active .bio {
		svg{
			display: none;
		}

		svg + svg{
			display: block;
		}
	}

	.bio-long{
		position: relative;
		background: #25282A;
		padding: 20px;
		color: #eee;
		display: none;
		text-align: left;

		h2{
			font-weight: bold;
			color: #fff;
			padding-bottom: 0;
		}

		h2 + p{
			padding-bottom: 15px;
		}

		p{
			line-height: 28px;
			padding-bottom: 10px;
		}

		a{
			color: #fff;
		}

		span{
			display: inline-block;
			background: #A1A1A1;
			height: 28px;
			width: 28px;
			margin-right: 5px;
			text-align: center;

			svg{
				margin-top: 4px;
				display: inline-block;
			}
		}

		a.get-vcard{
			border: 1px solid #eee;
			padding: 6px 8px;
			font-size: 13px;

			&:hover{
				background: #000;
			}
		}

	}

	#bio-row{
		position: relative;
		padding: 12px;
		background: #25282A;
		width: 100%;
		min-width: 100%;
		overflow: hidden;
		box-sizing: border-box;
		margin-top: -1px;

		.bio-long{
			display: block !important;
		}
	}

	nav{
		position: relative;
		border: 1px solid #111;
		margin-bottom: 50px;
		margin: auto;
		width: auto;

		a{
			position: relative;
			line-height: 25px;
			padding: 10px 20px;
			color: #000;
			font-weight: bold;
			text-align: center;
			align-items: center;
			display: block;
			// width: 12.5%;
			height: 50px;

			&:hover, &.active{
				background: #000;
				color: #fff;
			}
		}
	}


.tablet({
	li{
		width: 25%;
		box-sizing: border-box;
		border: 1px solid #fff;
	}

});


.desktop({
	.block-nav{
		display: inline-block;
		margin: auto;
	}
	nav{
		position: relative;
		border: 1px solid #111;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto auto 50px;

		a{
			position: relative;
			line-height: 25px;
			min-height: 50px;
			padding: 10px 20px;
			color: #000;
			text-align: center;
			align-items: center;
			justify-content: center;
			display: flex;
			font-weight: normal;

			&:hover{
				background: #000;
				color: #fff;
			}
		}
	}

	li{
		width: 14.2857%;
	}

	#bio-row{
		padding: 60px 25px;
	}

	.long-meta{
		float: left;
		width: 25%;

		p:last-of-type{
			padding-top: 15px;
		}
	}

	.long-bio{
		float: right;
		width: 70%;
	}

});
}

@media screen and (max-width: 768px){
	.block.team .block-nav{
		nav{
			border:0;
			margin-bottom: 30px;
			a{
				display: none;
				background: none;
				color: #000;
				text-align: left;
				font-weight: normal;
				height: auto;
				padding-left: 0;

				&.active{
					border-bottom: 1px solid #000;
					display: block;
					background: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2hldnJvbi1kb3duIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggMTAuMjkzbDUuNjQ2LTUuNjQ3YS41LjUgMCAwIDEgLjcwOC43MDhsLTYgNmEuNS41IDAgMCAxLS43MDggMGwtNi02YS41LjUgMCAwIDEgMC0uNzA4Ii8+Cjwvc3ZnPg==') no-repeat;
					background-size: 16px;
					background-position: right center;
				}

				br{
					display: none;
				}
			}

			&.show{
				a{
					display: block;
				}
			}
		}
	}
}
.block.spider{
	position: relative;
	padding: 80px 20px;	
	text-align: center;
	background: url(https://media.thinkresite.cloud/lull.jpg) center no-repeat;
	background-size: cover;

.tablet({
	padding: 29vh 0;
});


.desktop({

});
}
#footer{
	position: relative;
	background: @gray3;
	color: #fff;
	padding: 40px 20px;

	a{
		color: #fff;
		text-decoration: underline;

		&:hover{
			text-decoration: none;
		}
	}

	ul{
		padding: 30px 0 15px 0;
		display: flex;
		justify-content: space-between;
	}

	li{
		float: left;
		list-style: none;

		span{
			display: none;
		}

		a{
			text-decoration: none;
		}
	}

	p{
		padding-bottom: 15px;
	}

	.copyright{
		border-top: 2px solid #CECECE;
	}

	svg{
		color: #fff;
		height: 30px;
		width: 30px;
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;;
	}

	.copyright{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;;
		font-size: 1.4rem;
	}

	ul{
		padding-top: 0;
		a{
			transform: ease all .3s;
			opacity:1;

			&:hover{
				opacity: .5;
			}
		}
	}

	#jumper{
		position: fixed;
		display: none;
		bottom: 20px;
		right: 20px;
		height: 30px;
		width: 70px;
		text-align: center;
		background: #000;
		opacity: 0;
		transition: ease all .3s;

		svg{
			height: 30px;
			width: 50px;
			color: #fff;
			margin: auto;
		}
	}
});


.desktop({

});
}

.scrolled{
	#footer{
		#jumper{
			display: block;
			opacity: 1;
		}
	}
}
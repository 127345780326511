.block.testimonials{
	position: relative;
	padding: 80px 20px;
	color: #282938;
	

	h1,h2,h3,h4,h5,h6{
		font-weight: bold;
	}

	header{
		font-size: 1.5rem;
		color: #282938;
	}


	ul{
		list-style: none;
		font-size: 22px;
	}

	strong, em, b{
		display: block;
		font-size: 1.4rem;
	}

	em{
		font-size: 1.2rem;
		font-style: normal;
	}

	p + p{
		padding-top: 2rem;
	}

	nav{
		display: block;
		text-align: right;
	}

	button{
		height: 48px;
		width: 48px;
		border: 0;
		background: #fff;
		transition: ease all .3s;
		border-radius: 100%;
		cursor: pointer;

		svg{
			height: 22px;
			width: 22px;
			color: @gray3;
			margin-top: 6px;
			transition: ease all .3s;
		}

		&:hover{
			background: @gray2;

			svg{
				color: #fff;
			}
		}
	}

.tablet({
	.inner{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	header{
		width: 30%;

		h1,h2,h3,h4,h5,h6{
			font-size: 38px;
		}

		p{
			opacity: .8;
		}
	}

	article{
		width: 65%;
	}

	li{
		font-size: 32px;
		line-height: 1.3;
	}
});


.desktop({

});
}